@import '../theme-bootstrap';

.sd-mount-skeleton {
  position: relative;
  .sd-grid-skeleton {
    padding: 0 0 25px;
    @media #{$cr19-large-up} {
      padding: 0;
    }
    .elc-rating-add-to-bag-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media #{$cr19-medium-up} {
        display: none;
      }
      .elc-stars-simplified::before {
        height: 45px;
        background-position: center;
      }
    }
    .skeleton-shades-grid {
      display: flex;
      .elc-shade-image-wrapper {
        will-change: auto;
        width: 70px;
        @media #{$cr19-medium-up} {
          width: 35px;
        }
        @media #{$cr19-large-up} {
          width: 70px;
        }
      }
    }
    .elc-grid-column {
      display: none;
    }
    .elc-size-picker-container {
      display: flex;
      order: 0;
      margin: 16px 24px 0;
      max-width: calc(100% - 48px);
      gap: 12px;
      justify-content: center;
      border: none;
      overflow: hidden;
      @media #{$cr19-large-up} {
        margin: 28px 24px 0;
      }
      &:empty {
        display: none;
      }
      .elc-size {
        border: 1px solid $color-light-gray;
        height: 32px;
        border-radius: 0;
        color: $cr19-text-grey;
        width: auto;
        white-space: nowrap;
        text-align: center;
        padding: 8px 12px;
        cursor: pointer;
      }
    }
    .elc-product-images-wrapper {
      img {
        height: auto;
        max-width: 100%;
        background-color: $cr19-bg-light;
      }
    }
    .sd-grid-skeleton-items {
      .skeleton-item {
        width: auto;
        height: auto;
      }
      &.is-slider {
        @media #{$cr19-medium-up} {
          margin: 0 -12px;
        }
        .skeleton-item {
          padding: 0;
          margin: 0;
          position: relative;
          @media #{$cr19-medium-up} {
            padding: 0 12px;
          }
          &.elc-grid-item-tout {
            margin-inline-end: 20px;
            aspect-ratio: 9/16;
            @media #{$cr19-large-up} {
              margin-inline-end: 4px;
            }
          }
        }
      }
    }
    .sd-grid-skeleton-filters {
      padding: 0;
      gap: 25px;
      width: 100%;
      @media #{$cr19-medium-up} {
        width: auto;
      }
      .skeleton-bar {
        height: 40px;
        &-filters,
        &-sort {
          max-width: 100%;
        }
        &-sort {
          height: 58px;
          @media #{$cr19-medium-up} {
            position: absolute;
            #{$rdirection}: 0;
            top: 0;
            width: 15%;
          }
          @media #{$cr19-large-up} {
            height: 40px;
          }
        }
        &-filters {
          @include shimmer(1.5s, ease-in-out);
          height: 58px;
          @media #{$cr19-medium-up} {
            min-height: 126px;
            width: 100%;
          }
          & + .skeleton-bar-sort {
            float: $rdirection;
            width: calc(50% - 10px);
            @media #{$cr19-medium-up} {
              width: 15%;
            }
          }
        }
      }
      & + .sd-grid-skeleton-items {
        margin-top: 44px;
        top: auto;
        width: 100%;
        @media #{$cr19-medium-portrait-only} {
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        }
        @media #{$cr19-large-up} {
          grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }
      }
    }
    &.has-filters {
      flex-direction: column;
      @media #{$cr19-medium-up} {
        flex-direction: row;
      }
      .skeleton-item {
        padding: 10px 0 20px;
        @media #{$cr19-medium-up} {
          padding: 8px 8px 40px;
        }
        @media #{$cr19-large-up} {
          padding: 12px 12px 40px;
        }
      }
      .skeleton-box {
        margin: 10px 0;
        min-height: 450px;
        @media #{$cr19-medium-up} {
          margin: 0 8px;
          min-height: auto;
        }
        @media #{$cr19-large-up} {
          margin: 12px;
        }
      }
      .elc-product-image-badge-wrapper {
        position: relative;
        display: block;
      }
      .sd-grid-skeleton-filters {
        @media #{$cr19-medium-up} {
          flex: 25%;
          @include swap_direction(padding, 0 16px 0 0);
        }
        @media #{$cr19-large-up} {
          @include swap_direction(padding, 0 24px 0 0);
        }
      }
      .sd-grid-skeleton-items {
        position: relative;
        @media #{$cr19-medium-portrait-only} {
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
          margin: 0 -8px;
          flex: 75%;
        }
        @media #{$cr19-medium-up} {
          grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
          float: $rdirection;
          flex: 75%;
          margin: 70px -8px 0;
        }
        @media #{$cr19-large-up} {
          grid-template-columns: repeat(auto-fill, minmax(33.333%, 1fr));
          float: $rdirection;
          flex: 75%;
          margin: 70px -12px 0;
        }
      }
    }
    .elc-grid-item-product {
      .elc-shade-swatches-wrapper {
        margin: 0;
      }
      &:not(:has(.elc-shade-swatches-wrapper)):has(.elc-size-picker-container:empty) {
        .skeleton-details {
          @media #{$cr19-large-up} {
            padding-top: 61px;
          }
        }
      }
    }
    .skeleton-item {
      @media #{$cr19-large-up} {
        overflow: hidden;
      }
    }
    .elc-product-prices-wrapper {
      order: unset;
      display: flex;
      justify-content: center;
      @media #{$cr19-large-up} {
        width: 100%;
        margin: 8px 0;
      }
      .elc-product-price-row-wrapper {
        @include shimmer(1.5s, ease-in-out);
        height: 22px;
        width: 50px;
      }
    }
    .elc-product-display-name-wrapper {
      order: 1;
    }
    .elc-short-description-container {
      order: 2;
    }
    .elc-short-description-container,
    .elc-product-display-name-wrapper {
      text-align: center;
      width: 100%;
    }
    .elc-product-name-wrapper {
      padding: 0;
    }
    .elc-product-short-description-wrapper {
      @include text-body-text;
      color: $color-input-gray;
      order: 2;
    }
    .elc-shade-swatches-wrapper {
      & + .elc-size-picker-container {
        display: none;
      }
    }
    div.elc-product-shade-picker {
      display: flex;
      flex-direction: column-reverse;
      gap: 8px;
      .elc-grid-item-product {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .elc-shade-image-wrapper {
        width: 24px;
        height: 24px;
      }
      .skeleton-shades-grid {
        margin: 0 24px;
        gap: 14px;
        justify-content: center;
        @media #{$cr19-medium-up} {
          gap: 9px;
        }
        @media #{$cr19-xxxlarge-up} {
          gap: 16px;
        }
      }
      .elc-grid-column {
        display: block;
        .elc-subtitle--3.elc-product-shade-picker-title {
          margin: 16px 0 0;
          @media #{$cr19-large-up} {
            margin: 8px 0 0;
          }
        }
        .elc-product-shade-picker-title {
          @include text-special-body($font-family: $helvetica-regular-font-family);
          margin: 8px 0 0;
          text-align: center;
        }
      }
    }
    .skeleton-details {
      gap: 8px;
      &:not(:has(.elc-shade-swatches-wrapper)):not(:has(.elc-size-picker-container)) {
        .elc-product-display-name-link {
          padding-top: 16px;
        }
      }
      &-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        @media #{$cr19-large-up} {
          min-height: 185px;
          justify-content: flex-start;
        }
      }
      .elc-product-display-name-link {
        @include text-body-text($font-family: $helvetica-bold-font-family);
        padding-top: 8px;
      }
      .elc-rating-add-to-bag-container {
        flex-direction: column;
        text-align: center;
        gap: 8px;
        order: 3;
        @media #{$cr19-large-up} {
          display: flex;
        }
        .elc-stars-simplified {
          height: 20px;
          margin: 0;
          &::before {
            height: 20px;
          }
        }
      }
      .elc-add-to-bag {
        @include elc-button--light;
        text-transform: none;
        text-decoration: none;
        width: 100%;
        text-align: center;
        background: none;
        &::after {
          display: none;
        }
      }
      button.elc-add-to-bag {
        font-size: 1rem;
        line-height: normal;
      }
    }
  }
}
